import 'isomorphic-fetch';
import { isEmpty, isString, remove, uniqueId } from 'lodash-es';
import { getAuthorizationHeader } from 'services/SgConnect';

interface IErrorData {
    code: string;
    message: string;
    detailedMessage?: string;
    stackTrace?: string;
}

interface IValidationError {
    code: string;
    message: string;
    target: string;
    details: IValidationError[];
}

export class ApiException extends Error {
    public message: string;
    public status: number;
    public data?: IErrorData | IValidationError | null;

    constructor(message: string, status: number, data?: IErrorData | IValidationError | null) {
        const trueProto = new.target.prototype;
        super();

        this.message = message;
        this.status = status;
        this.data = data;

        Object.setPrototypeOf(this, trueProto);
    }
}

export const processResponse = async (response: Response): Promise<Response> => {
    const { status } = response;
    if (status === 401) {
        throw new ApiException('You are not authenticated', status);
    }
    if (status === 403) {
        throw new ApiException('You are not authorized to perform this action', status);
    }
    if (status === 404) {
        throw new ApiException('Not found', status);
    }
    if (status !== 200 && status !== 201 && status !== 204) {
        const responseText = await response.text();
        let message = '';
        let errorData;
        try {
            errorData = (JSON.parse(responseText) as any);
            message = errorData.message;
        }
        catch {
            message = 'An error has occured, please retry later!';
        }
        throw new ApiException(message, status, errorData);
    }
    return Promise.resolve(response);
};

interface IActiveRequestInfo {
    id: string;
    info: RequestInfo;
    init: RequestInit;
    controller?: AbortController;
}

export const activeRequests: { [requestUrl: string]: { [method: string]: IActiveRequestInfo[] } } = {};
const getRequestUrl = (activeRequestInfo: IActiveRequestInfo) => {
    return isString(activeRequestInfo.info) ? activeRequestInfo.info : (activeRequestInfo.info as Request).url;
};
const addActiveRequest = (activeRequestInfo: IActiveRequestInfo): void => {
    const url = getRequestUrl(activeRequestInfo);
    activeRequests[url] = activeRequests[url] || {};
    const method = activeRequestInfo.init.method || '';
    activeRequests[url][method] = activeRequests[url][method] || [];
    activeRequests[url][method].push(activeRequestInfo);
};
const removeActiveRequest = (activeRequestInfo: IActiveRequestInfo): void => {
    const url = getRequestUrl(activeRequestInfo);
    const method = activeRequestInfo.init.method || '';
    if (activeRequests[url] && activeRequests[url][method]) {
        remove(activeRequests[url][method], (m) => m.id === activeRequestInfo.id);
        if (!activeRequests[url][method].length) {
            delete activeRequests[url][method];
            if (isEmpty(activeRequests[url])) {
                delete activeRequests[url];
            }
        }
    }
};

export const abortRequests = (url: string, method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE' | 'OPTIONS' | 'HEAD' | 'CONNECT' | 'TRACE'): void =>
    Object.entries(activeRequests)
        .filter((entry) => entry[0].toLowerCase().includes(url.toLowerCase()))
        .map((entry) => entry[1][method.toUpperCase()])
        .reduce((previous, current) => current.concat(previous), [])
        .forEach((activeRequestsInfo) => {
            if (activeRequestsInfo.controller) {
                activeRequestsInfo.controller.abort();
            }
        });

let defaultAuthorizationHeader: string | null = null;
export const http = {
    setDefaultAuthorizationHeader(authorizationHeader: string): void {
        defaultAuthorizationHeader = authorizationHeader;
    },
    fetch(url: RequestInfo, requestInit?: RequestInit): Promise<Response> {
        const init = requestInit || {};
        init.headers = new Headers(init.headers);
        init.headers.append('Cache-Control', 'no-cache, no-store');
        init.headers.append('Pragma', 'no-cache');
        init.cache = 'no-store';
        init.credentials = 'omit';

        if (defaultAuthorizationHeader) {
            init.headers.append('Authorization', defaultAuthorizationHeader);
        }
        else {
            const authorizationHeader = getAuthorizationHeader();
            if (authorizationHeader) {
                init.headers.append('Authorization', authorizationHeader);
            }
        }

        const activeRequestInfo: IActiveRequestInfo = {
            id: uniqueId(),
            info: url,
            init,
        };

        const globalObject: any = typeof self !== 'undefined' ? self : global;
        const abortControllerIsDefined = (typeof (globalObject.Request) === 'function' && globalObject.Request.prototype.hasOwnProperty('signal')) || globalObject.AbortController;
        if (abortControllerIsDefined) {
            const controller = new AbortController();
            init.signal = controller.signal;
            activeRequestInfo.controller = controller;
        }

        addActiveRequest(activeRequestInfo);

        return fetch(url, init)
            .then((response: Response) => {
                removeActiveRequest(activeRequestInfo);
                return response;
            }, (error: Error | ApiException) => {
                removeActiveRequest(activeRequestInfo);
                throw error;
            })
            .then(processResponse);
    },
};

export default http;

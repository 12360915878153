import {
    BulkAddSubscriptionErrorResponse,
    CreateCategoryResponse,
    CreateGroupResponse,
    CreateSenderResponse,
    CreateThirdResponse,
    GicsSector,
    IsAnalystCandidateResponse,
    KeywordConfig,
    LoggedUser,
    Person,
    Product,
    ProductAuthor,
    ProductDistributionConfiguration,
    ProductDomainPublicationConfiguration,
    ProductInsightConfiguration,
    ProductPublicationConfig,
    PubExportProductConfig,
    ResearchCategorySubscriptionConfig,
    ResearchGroup,
    SubscribersCountForProducts,
    ThirdDuplicationCandidate,
    UpdateKeywordResearchCategoryRequest,
    WorkflowLanguage,
} from './InsightBackofficeApiClient';
import { ObjectsWithoutIdProperty, ObjectsWithoutIdPropertyEnum } from './InsightBackofficeApiSchema';

export class BaseInsightBackofficeApiSchema {
    protected static getId(value: ObjectsWithoutIdProperty, type: ObjectsWithoutIdPropertyEnum): string {
        switch (type) {

            case ObjectsWithoutIdPropertyEnum.CreateCategoryResponse:
                const createCategoryResponse = value as CreateCategoryResponse;
                return createCategoryResponse.categoryId.toString();

            case ObjectsWithoutIdPropertyEnum.CreateGroupResponse:
                return (value as CreateGroupResponse).groupId.toString();

            case ObjectsWithoutIdPropertyEnum.CreateSenderResponse:
                return (value as CreateSenderResponse).senderId.toString();

            case ObjectsWithoutIdPropertyEnum.IsAnalystCandidateResponse:
                return (value as IsAnalystCandidateResponse).icId;

            case ObjectsWithoutIdPropertyEnum.KeywordConfig:
                return (value as KeywordConfig).keywordId.toString();

            case ObjectsWithoutIdPropertyEnum.LoggedUser:
                return (value as LoggedUser).id;

            case ObjectsWithoutIdPropertyEnum.Person:
                return (value as Person).icId;

            case ObjectsWithoutIdPropertyEnum.Product:
                return (value as Product).id.toString();

            case ObjectsWithoutIdPropertyEnum.ProductAuthor:
                return (value as ProductAuthor).authorIcId;

            case ObjectsWithoutIdPropertyEnum.ProductDistributionConfiguration:
                return (value as ProductDistributionConfiguration).productId.toString();

            case ObjectsWithoutIdPropertyEnum.ProductDomainPublicationConfiguration:
                return (value as ProductDomainPublicationConfiguration).productId.toString();

            case ObjectsWithoutIdPropertyEnum.ProductInsightConfiguration:
                return (value as ProductInsightConfiguration).productId.toString();

            case ObjectsWithoutIdPropertyEnum.ProductPublicationConfig:
                return (value as ProductPublicationConfig).productId.toString();

            case ObjectsWithoutIdPropertyEnum.PubExportProductConfig:
                return (value as PubExportProductConfig).subjectId.toString();

            case ObjectsWithoutIdPropertyEnum.ResearchCategorySubscriptionConfig:
                return (value as ResearchCategorySubscriptionConfig).researchCategoryId.toString();

            case ObjectsWithoutIdPropertyEnum.ResearchGroup:
                return (value as ResearchGroup).groupId.toString();

            case ObjectsWithoutIdPropertyEnum.ThirdDuplicationCandidate:
                return (value as ThirdDuplicationCandidate).thirdId.toString();

            case ObjectsWithoutIdPropertyEnum.UpdateKeywordResearchCategoryRequest:
                return (value as UpdateKeywordResearchCategoryRequest).researchCategoryId.toString();

            case ObjectsWithoutIdPropertyEnum.WorkflowLanguage:
                const workflowLanguage = value as WorkflowLanguage;
                return workflowLanguage.workflowId.toString() + '_' + workflowLanguage.languageId.toString();

            case ObjectsWithoutIdPropertyEnum.GicsSector:
                const gicsSector = value as GicsSector;
                return gicsSector.id.toString();

            case ObjectsWithoutIdPropertyEnum.CreateThirdResponse:
                const createThirdResponse = value as CreateThirdResponse;
                return createThirdResponse?.thirdId?.toString();

            case ObjectsWithoutIdPropertyEnum.BulkAddSubscriptionErrorResponse:
                const bulkAddSubscriptionErrorResponse = value as BulkAddSubscriptionErrorResponse;
                return [bulkAddSubscriptionErrorResponse?.icId, bulkAddSubscriptionErrorResponse?.category, bulkAddSubscriptionErrorResponse?.product, bulkAddSubscriptionErrorResponse?.keyword].join('_');

            case ObjectsWithoutIdPropertyEnum.SubscribersCountForProducts:
                return (value as SubscribersCountForProducts).productId.toString();

            default:
                // The following line guarantees that every action in the KnownAction union has been covered by a case above
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const exhaustiveCheck: never = type;
        }
        return '';
    }
}
